import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import OpenAI from 'openai';
import {
  ChatCompletionCreateParams,
  ChatCompletionCreateParamsStreaming,
  ChatCompletionUserMessageParam,
  CompletionCreateParams,
  CompletionCreateParamsStreaming,
} from 'openai/resources';
import { Stream } from 'openai/streaming';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

class TextCompletionChunk {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: {
    index: number;
    finish_reason: string | null;
    text: string;
    logprobs: {
      top_logprobs: Array<Record<string, unknown>>;
    };
  }[];
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  openai = new OpenAI({
    apiKey: environment.aiConfig.apiKey,
    dangerouslyAllowBrowser: true,
    maxRetries: 5,
    defaultQuery: {},
    baseURL: environment.aiConfig.aiBaseUrl,
  });

  currentModel: string = environment.aiConfig.modelName;

  constructor() {
    this.getModel().then((model) => {
      this.currentModel = model;
    })

  }

  generateRandomNumber(): number {
    // let exclude = [1,2,4,6,7,8,9];

    // let include = [3,5,10];
    let include = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    return include[Math.floor(Math.random() * include.length)] ?? null;
  }

 getChatCompletion(
  history: ChatCompletionUserMessageParam[],
  message: string,
  stream: boolean = true,
  useTemplate: boolean = true
): Observable<string> {
  if (useTemplate) {
    // Apply any templating logic if needed
    message = `${message}`;
  }

  const body: ChatCompletionCreateParams = {
    model: this.currentModel,
    messages: [...history, { role: "user", content: message }],
    stream,
  };

  return this.getChatCompletionRequest(body);
}


async getModel() {
  return this.openai.models.list().then((response) => {
    if (response?.data?.length > 0) {
      const models = response?.data;
      const currentModel = models.find((model) => model.id === environment.aiConfig.modelName);
      if (currentModel) {
        return currentModel.id;
      } else {
        return models[0].id;
      }
    }
  });
}
getChatCompletionRequest(body: ChatCompletionCreateParams): Observable<string> {
  let receivedString = '';

  return new Observable<string>((observer) => {
    const request = this.openai.chat.completions.create({ ...body, model: this.currentModel });

    request
      .then(async (stream) => {
        const decoder = new TextDecoder('utf-8');

        if (this.isStream(stream)) {
          for await (const chunk of stream) {
            receivedString += chunk.choices[0].delta?.content ?? '';

            if (chunk.choices[0].finish_reason === 'stop') {
              observer.complete();
              return;
            }

            if (chunk.choices[0]?.delta?.content) {
              observer.next(chunk.choices[0].delta.content);
            }
          }
        } else {
          observer.next(stream.choices[0].message.content);
        }
      })
      .catch((err: Error) => {
        Swal.fire({
          title: 'Temporary Unavailability',
          text: 'The AI service is currently unavailable. Please try again later.',
          icon: 'warning',
          preConfirm: () => observer.error(err),
        });
      });
  });
}


  //  generateRandomNumber(): number {
  //   // let exclude = [1,2,4,6,7,8,9];

  //   // Generate a random number between 1 and 10
  //   const randomNumber: number = Math.floor(Math.random() * 10) + 1;
  //   if (exclude.includes(randomNumber)) {
  //     return this.generateRandomNumber();
  //   }
  //   return randomNumber;
  // }

  getCompletion(
    prompt: string,
    stream: boolean = true,
  ): Observable<string> {
    return this.getChatCompletion([], prompt, stream);
  }
  
 
  

  isStream<T>(obj: any): obj is Stream<T> {
    return typeof obj?.[Symbol.asyncIterator] === 'function';
  }
}
