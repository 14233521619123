import { Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  PeriodResultModel,
  PeriodTypeEnum,
  PeriodTypeModel,
  PeriodTypeValue,
  PeriodUnitEnum,
  PeriodUnitModel,
} from 'src/app/models/owner-dashboard';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-period-button',
  templateUrl: './period-button.component.html',
  styleUrls: ['./period-button.component.css'],
})
export class PeriodButtonComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dropRef') dropRef: NgbDropdown;

  customRange: {
    period: PeriodTypeValue;
    enabled: boolean;
    item: PeriodTypeModel;
  } = {
    period: {
      startDate: null,
      endDate: null,
    },
    enabled: false,
    item: {
      displayName: 'Custom Range',
      enum: PeriodTypeEnum.custom,
      value: {
        startDate: null,
        endDate: null,
      },
    },
  };
  periodLimits: PeriodTypeValue;
  @Input() periodButtonClasses = 'btn-light btn-active-light-primary shadow-sm';
  @Input('periodLimits') set _periodLimits(value: PeriodTypeValue) {
    this.periodLimits = value;
    this.calculateDates(value);
  }

  @Input() enableUnitFilter: boolean = false;
  // Emitters

  _periodObject: PeriodResultModel;

  @Output() periodObjectChange = new EventEmitter<PeriodResultModel>();
  @Input('periodObject')
  set periodObject(value: PeriodResultModel) {
    if (
      value &&
      (value?.period?.value?.startDate !==
        this._periodObject?.period?.value?.startDate ||
        value?.period?.value?.endDate !==
          this._periodObject?.period?.value?.endDate)
    ) {
      this._periodObject = value;
    }

    // if (value && value.unit.enum !== this._periodObject?.unit.enum) {
    //   this._periodObject.unit = value.unit;
    // }
  }

  onPeriodTypeSetSubscription: Subscription;
  // Input Data
  @Input() unitTypes: PeriodUnitModel[] = [
    {
      index: 0,
      displayName: 'Day',
      enum: PeriodUnitEnum.day,
    },
    {
      index: 1,
      displayName: 'Week',
      enum: PeriodUnitEnum.week,
    },
    {
      index: 2,
      displayName: 'Month',
      enum: PeriodUnitEnum.month,
    },
    {
      index: 3,
      displayName: 'Year',
      enum: PeriodUnitEnum.year,
    },
  ];

  // Data
  // Input Data
  @Input() periodTypes: PeriodTypeModel[] = [
    new PeriodTypeModel({
        index: 5,
        displayName: 'All Time',
        enum: PeriodTypeEnum.all_time,
        by: PeriodUnitEnum.day,
    }),
    new PeriodTypeModel({
        index: 0,
        displayName: 'Last 7 days',
        enum: PeriodTypeEnum.last7days,
        by: PeriodUnitEnum.day,
    }),
    new PeriodTypeModel({
        index: 1,
        displayName: 'Last 30 days',
        enum: PeriodTypeEnum.last30days,
        by: PeriodUnitEnum.day,
    }),
    new PeriodTypeModel({
        index: 2,
        displayName: 'Last 3 months',
        enum: PeriodTypeEnum.last3months,
        by: PeriodUnitEnum.week,
    }),
    new PeriodTypeModel({
        index: 3,
        displayName: 'Last 6 months',
        enum: PeriodTypeEnum.last6months,
        by: PeriodUnitEnum.week,
    }),
    new PeriodTypeModel({
        index: 4,
        displayName: 'Last year',
        enum: PeriodTypeEnum.lastyear,
        by: PeriodUnitEnum.month,
    }),
];


  // Data
  // @Input() periodObject: PeriodResultModel =

  constructor(private cdr: ChangeDetectorRef, private toastr: ToastrService) {}
  ngAfterViewInit(): void {
    this.calculateDates({startDate: this.periodLimits.startDate, endDate: new Date().getTime() / 1000000});
    if (!!this._periodObject) {
      this.onPeriodClicked(this._periodObject.period);
    }
    else {
      this.onPeriodClicked(this.periodTypes[0]);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // this.onPeriodTypeSetSubscription.unsubscribe();
  }

  calculateDates(limits: PeriodTypeValue) {
    const minDate = new Date(limits.startDate * 1000);
    const maxDate = new Date(limits.endDate * 1000);
    const currentDate = new Date();
    let startDate: Date;
    let endDate = new Date(currentDate);
    this.periodTypes = this.periodTypes.map((period) => {
      switch (period.enum) {
        case PeriodTypeEnum.last7days:
          startDate = new Date(currentDate);
          startDate.setDate(currentDate.getDate() - 6);


          break;
        case PeriodTypeEnum.last30days:
          startDate = new Date(currentDate);
          startDate.setDate(currentDate.getDate() - 30);
          break;
        case PeriodTypeEnum.last3months:
          startDate = new Date(currentDate);
          startDate.setMonth(currentDate.getMonth() - 2);
          startDate.setDate(currentDate.getDate()); // Start from the beginning of the month
          break;
        case PeriodTypeEnum.last6months:
          startDate = new Date(currentDate);
          startDate.setMonth(currentDate.getMonth() - 5);
          startDate.setDate(1); // Start from the beginning of the month
          break;
        case PeriodTypeEnum.lastyear:
          startDate = new Date(currentDate);
          startDate.setFullYear(currentDate.getFullYear() - 1);
          startDate.setMonth(0); // Start from January
          startDate.setDate(1); // Start from the beginning of the month
          break;
        case PeriodTypeEnum.all_time:
          startDate = minDate;
          endDate = currentDate;
          this.periodTypeChange(period);
          break;
        default:
          // Handle default case
          break;
      }

      // let isOutOfRange =
      //   Math.floor(minDate.getTime() / 1000000) >
      //   Math.floor(startDate.getTime() / 1000000);
      // period.outOfRange = isOutOfRange;

      const startDateNumber = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
  
      const endDateNumber = !!endDate
        ? Date.UTC(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59,
            59
          )
        : null;

        const periodValues = {
          startDate: Math.floor(startDateNumber / 1000),
          endDate: Math.floor(endDateNumber / 1000),
        };

        return {...period, value: periodValues};
      
    });

    // Now you have the calculated startDate and endDate for your filter.
  }

  closeDropdown() {
    if (this.dropRef) {
      this.dropRef.close();
    }
  }

  enableCustomRange() {
    this.customRange.enabled = true;
  }

  onSelectCustomPeriod(value: { selectedDates: Date[] }) {
    const options = { timeZone: 'UTC' };

    // Format dates as strings in UTC time zone
    const startDateNumber = Date.UTC(
      value.selectedDates[0].getFullYear(),
      value.selectedDates[0].getMonth(),
      value.selectedDates[0].getDate()
    );

    const endDateNumber = !!value.selectedDates[1]
      ? Date.UTC(
          value.selectedDates[1].getFullYear(),
          value.selectedDates[1].getMonth(),
          value.selectedDates[1].getDate(),
          23,
          59,
          59
        )
      : null;

    if (value?.selectedDates?.length) {
      this.customRange.period.startDate = Math.floor(startDateNumber / 1000);
      this.customRange.period.endDate = !!value.selectedDates[1]
        ? endDateNumber / 1000
        : null;
    }
  }

  onApplyCurrentSelectedCustomPeriod() {
    const tempItem = JSON.parse(JSON.stringify(this.customRange.item));
    tempItem.value.startDate = this.customRange.period.startDate;
    tempItem.value.endDate = this.customRange.period.endDate;
    this.periodTypeChange(tempItem, true);
    this.closeDropdown();
  }

  resetCustomRange() {
    this.customRange.enabled = false;
    this.customRange.period = {
      startDate: null,
      endDate: null,
    };
    this.customRange.item.value = {
      endDate: null,
      startDate: null,
    };
    // this.customPeriodSelectedDatePeriod
  }

  unitTypeChange(index: number) {
    const tempPeriodObject: PeriodTypeModel = JSON.parse(
      JSON.stringify(this._periodObject.period)
    );

    this._periodObject.unit = JSON.parse(JSON.stringify(this.unitTypes[index]));
    tempPeriodObject.by = this._periodObject.unit.enum;

    this._periodObject.period = tempPeriodObject;
  }

  onPeriodClicked(period: PeriodTypeModel) {
    if (!period?.outOfRange) {
      this.dropRef.close();
      this.periodTypeChange(period);
    } else {
      this.toastr.warning('This period is not Available', 'Out Of Range');
    }
  }
  periodTypeChange(period: PeriodTypeModel, custom: boolean = false) {
    if (!custom) {
      this.resetCustomRange();
    }

    console.log(period);

    this.periodObjectChange.emit({ period, unit: { enum: period.by } });
    this._periodObject = { period, unit: { enum: period.by } };
    const tempUnitIndex = this.unitTypes.findIndex(
      (a) => a.enum == this._periodObject.period.by
    );
    if (tempUnitIndex != -1) {
      this._periodObject.unit = JSON.parse(
        JSON.stringify(this.unitTypes[tempUnitIndex])
      );
    }
  }
}
