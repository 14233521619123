import EditorJS, { API, BlockMutationEvent } from '@editorjs/editorjs';
import * as cheerio from 'cheerio';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import DragDrop from 'editorjs-drag-drop';
import edjsParser from 'editorjs-parser';
import { Observable, Subject } from 'rxjs';
import { AiService } from '../../services/ai.service';

export type AIStatusType = 'none' | 'loading' | 'done' | 'finalizing' | 'error';
export default class LearmoEditorJS {
  aiTimerConfig: any = {};
  preventEditorSave = false;
  aiStatus: AIStatusType = 'none';
  aiGeneratedContent = '';

  isInitialized = false;
  isEditorReady = false;
  isInitialValueSet: boolean = false;
  editor: EditorJS;
  editorID: string;
  muteChangeEmit: boolean;
  parser: edjsParser = new edjsParser();
  content: string = '';

  lastEmitContent: string = '';

  debonucing: boolean = false;
  private changeSubject = new Subject<string>();
  private readySubject = new Subject<void>();

  onChange$: Observable<string> = this.changeSubject.asObservable();
  onReady$: Observable<void> = this.readySubject.asObservable();

  promptFunction?: (...args) => string
  //   onChange: (HTMLContent: string) => void = (HTMLContent: string) => {};
  //   onReady: () => void = () => {};

  constructor(initialConfiguration: { content?: string; editorID?: string, promptFunction?: (...args) => string }, private ai?: AiService) {
    Object.assign(this, initialConfiguration);
    this.initializeEditor();
  }

  initializeEditor(editorID = this.editorID) {
    this.isInitialized = true;
    console.log('Initializing EditorID', editorID);
    this.editor = new EditorJS({
      holder: this.editorID,
      hideToolbar: false,
        onChange: (api: API, event: BlockMutationEvent | BlockMutationEvent[] | any) => {
          console.log(event);
          const isRealEvent = (event as CustomEvent)?.type == 'block-changed' || (event as CustomEvent).type == 'block-removed'
          const isRealEventArray = event instanceof Array && (event as CustomEvent[])?.some(event => event.type == 'block-changed' || event.type == 'block-removed');
          if (isRealEvent || isRealEventArray) {
            if (!this.preventEditorSave) {
                this.saveEditorCurrentValue();
            }
          }
        },
      onReady: () => {
        new DragDrop(this.editor);
        if (this.content) {
          this.setHTMLContent(this.content, true);
        }
        setTimeout(() => {
          this.isEditorReady = true;
          this.readySubject.next();  
        }, 500);
        
      },
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['bold', 'link'],
        },
        list: {
          class: List,
          inlineToolbar: ['link', 'bold'],
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
    });
  }

  clearEditor() {
    this.preventEditorSave = true;
    this.editor.blocks.clear();
    this.content = '';
    this.resetAiState();
    this.preventEditorSave = false;
  }

  public setHTMLContent(value: string, muteChangeEmit: boolean = false) {
    if (muteChangeEmit) {
      this.muteChangeEmit = true;
    }
    this.content = value;
    return this.editor.blocks.renderFromHTML(value).finally(() => {
      // if (this.aiStatus === 'finalizing' || this.aiStatus === 'done') {

      //     this.scrollToEditorEnd();

      // }
    });
  }

  setAiStatus(status: AIStatusType) {
    this.aiStatus = status;
    switch (status) {
      case 'done':
        setTimeout(() => {
          this.scrollToEditorEnd();
          setTimeout(() => {
            this.setAiStatus('none');
          }, 2000);
        }, 100);

        break;

      default:
        break;
    }
  }

  scrollToEditorEnd() {
    const editorElement = document.getElementById(`${this.editorID}`);
    const writingElement = document.getElementById(`${this.editorID}_writing`);

    editorElement ? console.log('scrolling editor') : console.log('no editor');
    writingElement
      ? console.log('scrolling writing')
      : console.log('no writing');

    editorElement?.scrollBy({
      top: editorElement.scrollHeight,
      behavior: 'instant' as ScrollBehavior,
    });
    writingElement?.scrollBy({
      top: writingElement.scrollHeight,
      behavior: 'instant' as ScrollBehavior,
    });
  }
  //   public setAiGeneratedToEditor() {
  //     this.setHTMLToEditor(this.aiGeneratedContent, { emitValue: false });
  //     this.aiGeneratedContent = ''
  //   }
  public saveEditorCurrentValue() {
    this.editor
      .save()
      .then((jsonData) => {
        const HTMLContent = this.parser.parse(jsonData);
        if (!this.muteChangeEmit) {
          this.lastEmitContent = HTMLContent;
          this.changeSubject.next(HTMLContent);
          //   this.onChange(HTMLContent);
        } else {
          this.muteChangeEmit = false;
        }
      })
      .catch((error) => console.log('Saving failed: ', error));
  }

  //   Util Functions
  public optimizeHTMLContent = (content: string) => {
    if (!content) return '';
    const $ = cheerio.load(content);

    $('img, iframe').remove();

    const body = $('body');

    body.contents().each((index, element) => {
      const elementHTML = $(element);
      const text = elementHTML.text().trim();

      let matchedPattern: string | null | RegExp = null;
      let listType = 'ul';

      if (text.includes('- ')) {
        matchedPattern = '- ';
        listType = 'ul';
      } else if (text.includes('* ')) {
        matchedPattern = '* ';
        listType = 'ul';
      } else if (text.includes('* ')) {
        matchedPattern = '• ';
        listType = 'ul';
      } else if (text.match(/^\d+\.\s/gm)) {
        matchedPattern = /^\d+\.\s/gm;
        listType = 'ol';
      }

      if (matchedPattern) {
        const items = text
          .split(matchedPattern)
          .map((item) => item.trim())
          .filter((item) => item.length > 0);

        const firstItemHasHyphen = items[0].startsWith(
          matchedPattern.toString()
        );
        const firstTitle = firstItemHasHyphen ? '' : items[0];
        const filteredItems = firstItemHasHyphen ? items : items.slice(1);

        if (filteredItems.length < 2) return;

        const listItems = filteredItems
          .map((item) => `<li>${item}</li>`)
          .join('');
        const listElement = `<${listType}>${listItems}</${listType}>`;

        elementHTML.replaceWith(`<div>${firstTitle}${listElement}</div>`);
      }
    });

    $('li').each((index, element) => {
      const $li = $(element);
      if (
        $li.parent().prop('tagName').toLowerCase() !== 'ul' &&
        $li.parent().prop('tagName').toLowerCase() !== 'ol'
      ) {
        const $ul = $('<ul>');
        $li.nextUntil('li').addBack().appendTo($ul);
        $ul.insertAfter($li.prevAll('li').last());
      }
    });

    const bodyContent = $('body').html();

    return bodyContent;
  };

  static generateUniqueID(): string {
    const randomString = Math.random().toString(36).substring(2, 8);
    const timestamp = new Date().getTime();
    return `editor-${randomString}${timestamp}`;
  }

  // Ai Based Features

  startGenerating = async () => {
    let data: {
      completions: string;
    } = {
      completions: '',
    };

    const prompt = this.promptFunction();
    let lastChar: string = null;
    this.setAiStatus('loading');
    this.startTimer();
    this.clearEditor();
    return new Promise((resolve) => {
      this.ai.getCompletion(prompt, true).subscribe({
        next: (response: string) => {
          //

          this.aiGeneratedContent += response;
          this.scrollToEditorEnd();
          if (response !== '\n' && !this.aiGeneratedContent.length) {
            this.clearEditor();
          }

          lastChar = data.completions;
        },
        error: (error) => {
          this.setAiStatus('error');
          this.clearTimer();
        },
        complete: () => {
          this.stopTimer();
          this.setAiStatus('finalizing');

          // this.editorController.aiGeneratedContent =
          //   this.editorController.optimizeHTMLContent(
          //     this.editorController.aiGeneratedContent
          //   );
          this.aiGeneratedContent = this.optimizeHTMLContent(
            this.aiGeneratedContent
          )
          this.setHTMLContent(this.aiGeneratedContent).finally(() => {
            setTimeout(() => {
              this.saveEditorCurrentValue();
              this.setAiStatus('done');
              this.resetAiState();
              resolve(true);
            }, 1500);
          });
        },
      });
    });
  };

  resetAiState() {
    this.aiGeneratedContent = '';
  }



  // Timer Functions
  // Timer Functions

  startTimer(): void {
    this.aiTimerConfig.startTime = new Date().getTime();

    this.aiTimerConfig.timerId = setInterval(() => {
      const currentTime = new Date().getTime();
      this.aiTimerConfig.elapsedTime =
        currentTime - this.aiTimerConfig.startTime;
    }, 100);
  }

  clearTimer() {
    this.stopTimer();
    this.aiTimerConfig.elapsedTime = null;
  }

  stopTimer() {
    clearInterval(this.aiTimerConfig.timerId);
  }

  resetTimer() {
    this.aiTimerConfig.elapsedTime = 0;
  }
}
